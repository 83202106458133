import { Hit, Match } from '@/models/screen-result.d';
import { JSONPath } from 'jsonpath-plus';
import jp from 'jsonpath';

function splitToken(
  tokens: Token[],
  tokenToSplit: Token,
  positionToSplit: number,
  textToAdd: string,
) {
  const beginText = tokenToSplit.text.substring(0, positionToSplit - tokenToSplit.index);
  const endText = tokenToSplit.text.substring(
    positionToSplit - tokenToSplit.index,
    tokenToSplit.text.length,
  );

  tokens.splice(tokens.findIndex((token) => token === tokenToSplit), 1);

  if (beginText.length > 0) {
    tokens.push({
      text: beginText,
      index: tokenToSplit.index,
      length: beginText.length,
    });
  }
  tokens.push({
    text: textToAdd,
    index: tokenToSplit.index + beginText.length,
    length: 0,
  });

  if (endText.length > 0) {
    tokens.push({
      text: endText,
      index: tokenToSplit.index + beginText.length,
      length: endText.length,
    });
  }
}

function processOverlappingHits(hits: Hit[]): SpanInfo[] {
  const matches = hits.flatMap((hit) => hit.matches.map((match) => ({ match, hit })));

  const groups = matches.reduce(
    (acc, pair) => {
      if (pair.match.field in acc) {
        acc[pair.match.field].push(pair);
      } else {
        acc[pair.match.field] = [pair];
      }
      return acc;
    },
    {} as KeyValuePair,
  );

  function compareGroups(a: MatchPair, b: MatchPair) {
    if (b.match.position === a.match.position) {
      return b.match.length - a.match.length;
    }
    return b.match.position - a.match.position;
  }

  Object.keys(groups).forEach((key) => {
    const group = groups[key];
    group.sort(compareGroups);
  });

  const spans: SpanInfo[] = [];

  Object.keys(groups).forEach((key) => {
    const group: MatchPair[] = groups[key];
    const positionRules: any[][] = [];

    for (let i = 0; i < group.length; i += 1) {
      for (
        let j = group[i].match.position;
        j < group[i].match.position + group[i].match.length;
        j += 1
      ) {
        if (positionRules[j] === undefined) {
          positionRules[j] = [];
        }
        if (positionRules[j].find((x: Hit) => (group[i].hit.rule.id === x.rule.id)) === undefined) {
          positionRules[j].push(group[i].hit);
        }
      }
    }

    let previousRules = '';

    function resetSpans(i: number) {
      if (spans.length > 0) {
        if (spans[spans.length - 1].length === 0) {
          spans[spans.length - 1].length = i - spans[spans.length - 1].position;
        }
      }
    }

    for (let i = 0; i < positionRules.length; i += 1) {
      const position = positionRules[i];
      if (position !== undefined) {
        if (JSON.stringify(position) !== previousRules) {
          previousRules = JSON.stringify(position);
          resetSpans(i);
          spans.push({
            field: key, position: i, length: 0, hits: position,
          });
        }
        if (i === positionRules.length - 1) {
          resetSpans(i + 1);
        }
      } else {
        previousRules = '';
        resetSpans(i);
      }
    }
  });

  return spans;
}

function getHitTitle(hits: Hit[]) {
  function hitSort(hit1: Hit, hit2: Hit): number {
    if (hit1.library.name > hit2.library.name) {
      return 1;
    }
    if (hit1.library.name < hit2.library.name) {
      return -1;
    }

    if (hit1.rule.name > hit2.rule.name) {
      return 1;
    }

    return -1;
  }
  let title = '';
  const prevHits: any[] = [];
  const orderedHits = hits.sort((a, b) => hitSort(a, b));
  for (let i = 0; i < orderedHits.length; i += 1) {
    if (prevHits.find((id) => id === orderedHits[i].rule.id) === undefined) {
      title += `${hits[i].library.name} - ${hits[i].rule.name}&#10;`;
      prevHits.push(hits[i].rule.id);
    }
  }
  return title.replace(/&#10;$/g, '');
}

export default async function formatScreening(jsonScreening: any, hits: Hit[]): Promise<string> {
  const tokenProps: TokenProperty[] = [];

  const groups = processOverlappingHits(hits);

  for (let i = 0; i < groups.length; i += 1) {
    const {
      field, position, length, hits: processedHits,
    } = groups[i];

    if (!tokenProps.find((e) => e.propertyPath === field)) {
      const value = JSONPath({ json: jsonScreening, path: field, resultType: 'value' })[0];
      tokenProps.push({
        propertyPath: field,
        tokens: [
          {
            text: value,
            index: 0,
            length: value.length,
          },
        ],
      });
    }

    const tokenProp = tokenProps.find((e) => e.propertyPath === field);

    // eslint-disable-next-line no-unused-expressions
    tokenProp?.tokens.sort((a, b) => b.index - a.index);
    const index = position;
    const startToken = tokenProp?.tokens.find(
      (token) => token.index <= index && token.length > 0
    );
    const rulename = getHitTitle(processedHits);

    if (tokenProp && startToken) {
      splitToken(tokenProp?.tokens, startToken, position, `<span title="${rulename}">`);
    }

    // eslint-disable-next-line no-unused-expressions
    tokenProp?.tokens.sort((a, b) => b.index - a.index);
    const endToken = tokenProp?.tokens.find(
      (token) => token.index <= (index + token.length) && token.length > 0
    );

    if (tokenProp && endToken) {
      splitToken(tokenProp?.tokens, endToken, position + length, '</span>');
    }
  }

  for (let i = 0; i < tokenProps.length; i += 1) {
    let replaceString = '';

    tokenProps[i].tokens.sort((a, b) => {
      if (a.index === b.index) {
        if (a.length === 0) {
          if (a.text.substring(2) === '</') {
            return -1;
          }
          return 0;
        }
        return 1;
      }

      return a.index - b.index;
    }).map(
      (a) => { replaceString += a.text; return ''; },
    );

    const jsonPath = JSONPath({ path: tokenProps[i].propertyPath, json: jsonScreening, resultType: 'path' });
    jp.apply(jsonScreening, jsonPath[0], () => replaceString);
  }

  return JSON.stringify(jsonScreening, null, 2).replace(/\\"/g, '"');
}

interface Token {
  text: string;
  index: number;
  length: number;
}

interface TokenProperty {
  propertyPath: string;
  tokens: Token[];
}

interface KeyValuePair {
  [key: string]: [MatchPair];
}

interface MatchPair {
  match: Match;
  hit: Hit;
}

interface SpanInfo {
  field: string;
  position: number;
  length: number;
  hits: Hit[];
}
